<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="subheading">Aktive Abfragen</span></v-card-title
      >
      <v-data-iterator
        :items="requestsMatched"
        hide-default-footer
        sort-by="timeSlots.start.timestamp"
        :sort-desc="false"
        no-data-text="Keine Abfragen bevorstehend"
      >
        <template v-slot:default="props">
          <v-card flat>
            <v-list>
              <v-list-item
                v-for="request in props.items"
                :key="request.meta.id"
                link
                dense
                :to="{
                  name: 'scheduling-requests-details-tab-general',
                  params: { itemId: request.meta.id },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 font-weight-medium mb-1">
                    <span>{{ request.title }} – {{ request.type.title }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-if="responseIsComplete(request)"
                      x-small
                      color="green lighten-4"
                      class="font-weight-medium green--text text--darken-4 my-1"
                      >Beantwortet</v-chip
                    >
                    <v-chip
                      v-else-if="
                        request.timeSlots.data.length >
                        request.userResponse.status.length
                      "
                      x-small
                      color="orange lighten-4"
                      class="font-weight-medium orange--text text--darken-4 my-1"
                      >Rückmeldung unvollständig</v-chip
                    >
                    <v-chip
                      v-else
                      x-small
                      color="red lighten-4"
                      class="font-weight-medium red--text text--darken-4 my-1"
                      >Unbeantwortet</v-chip
                    >
                    <p style="line-height: 1.6" class="mt-1 mb-0">
                      <span
                        ><v-icon small left>mdi-calendar-range</v-icon
                        >{{ request.timeSlots.start.formattedTimestamp }}
                        -
                        {{ request.timeSlots.end.formattedTimestamp }} </span
                      ><br />
                      <span v-if="request.location.name">
                        <v-icon small left>mdi-map-marker</v-icon
                        >{{ request.location.name }}<br
                      /></span>
                      <v-icon small left>mdi-calendar-multiselect</v-icon
                      >{{ request.timeSlots.data.length }}
                      Slots
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="red darken-1" icon>
                    <v-icon large> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                link
                dense
                style="height: 10px"
                :to="{
                  name: 'scheduling-requests',
                }"
              >
                <v-list-item-content> </v-list-item-content>
                <v-list-item-action-text
                  >Weitere Abfragen anzeigen</v-list-item-action-text
                >
                <v-list-item-action>
                  <v-btn color="red darken-1" icon>
                    <v-icon> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
        <template v-slot:no-data>
          <div
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <p class="subtitle-1">Keine Abfragen vorhanden.</p>
          </div>
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
import { AVAILABILITY } from "@/store/modules.js";
import { GET_REQUESTS } from "@/store/action-types.js";
export default {
  name: "requests-dashboard-card",
  props: {},
  components: {},
  computed: {
    requests() {
      return this.$store.state.availability.requests;
    },
    requestsStatus() {
      return this.$store.state.availability.requestsStatus;
    },
    requestsMatched() {
      // Add status to requests
      const b = this.requests.map((request) => {
        const u = this.requestsStatus.find(
          (u) => u.userResponse.meta.requestId === request.meta.id
        );
        return {
          ...request,
          ...u,
        };
      });
      return b;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_REQUESTS}`, {
        organizationId: this.$route.params.organizationId,
        withStatus: true,
        query: {
          onlyOwn: true,
          ownUserGroup: this.$store.state.organization.membership.group.id,
        },
        // withStatus: this.$store.getters["organization/checkPermissionByID"](
        //   `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`
        // )
        //   ? true
        //   : false,
        paginationLimit: 3,
      });
    },
    responseIsComplete(data) {
      console.log(data);
      const timeSlots = data.timeSlots.data;
      const response = data.userResponse.status;

      if (timeSlots.length !== response.length) {
        // Response can't be complete if there are more time slots than responses
        return false;
      } else {
        // Check if there is a matching response for each time slot by id
        const responseIsComplete = timeSlots.every((timeSlot) => {
          const matchingResponse = response.find(
            (response) => response.id === timeSlot.id
          );
          return matchingResponse;
        });
        return responseIsComplete;
      }
    },
  },
};
</script>
