<template>
  <div>
    <v-card>
      <v-card-title> <span class="subheading">Neuigkeiten</span></v-card-title>
      <v-data-iterator
        :items="news"
        hide-default-footer
        sort-by="publishedAt.timestamp"
        sort-desc
        no-data-text="Keine Neuigkeiten vorhanden."
      >
        <template v-slot:default="props">
          <v-card flat>
            <v-list>
              <news-list-items
                :items="props.items"
                dense
                link
              ></news-list-items>
              <v-divider></v-divider>
              <v-list-item
                link
                dense
                style="height: 10px"
                :to="{
                  name: 'my-organization-news',
                }"
              >
                <v-list-item-content> </v-list-item-content>
                <v-list-item-action-text
                  >Weitere Neuigkeiten anzeigen</v-list-item-action-text
                >
                <v-list-item-action>
                  <v-btn color="red darken-1" icon>
                    <v-icon> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
        <template v-slot:no-data>
          <div
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <p class="subtitle-1">Keine Neuigkeiten vorhanden.</p>
          </div>
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
import NewsListItems from "@/components/my-organization/news/NewsListItems.vue";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_NEWS } from "@/store/action-types.js";
export default {
  name: "news-dashboard-card",
  props: {},
  components: { NewsListItems },
  computed: {
    news() {
      return this.$store.state.myOrganization.news;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_NEWS}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          onlyOwn: false,
          //   onlyOwn: true,
          //   ownUserGroup: this.$store.state.organization.membership.group.id,
        },
        paginationLimit: 3,
      });
    },
  },
};
</script>
