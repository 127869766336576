<template>
  <div>
    <template>
      <template v-for="(link, index) in linksLocal">
        <v-card outlined class="mb-3" :key="index">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="link.title"
                  outlined
                  label="Bezeichnung"
                  hint="Diese Bezeichnung wird zusammen mit dem Link angezeigt."
                  :rules="[rules.required]"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="link.url"
                  outlined
                  label="Link"
                  :rules="[rules.required, rules.link]"
                  prepend-inner-icon="mdi-link"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="link.icon"
                  label="Icon auswählen"
                  persistent-hint
                  required
                  prefix="mdi-"
                  :rules="[rules.required]"
                  outlined
                  hint="ID von Icon aus Material Design Icons Bibiliothek angeben"
                  prepend-inner-icon="mdi-emoticon"
                >
                  <template v-slot:append-outer
                    ><v-btn depressed small @click="openMdiIconLibrary"
                      >Icons durchsuchen
                      <v-icon right>mdi-open-in-new</v-icon></v-btn
                    ></template
                  ></v-text-field
                >

                <v-avatar
                  :color="`${getMaterialColor(link.color)} lighten-5`"
                  rounded
                  class="mr-4"
                >
                  <v-icon :color="link.color">mdi-{{ link.icon }}</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="link.color"
                  label="Farbe wählen"
                  persistent-hint
                  required
                  :rules="[rules.required]"
                  disabled
                  outlined
                  prepend-inner-icon="mdi-palette"
                ></v-text-field>
                <v-color-picker
                  v-model="link.color"
                  :swatches="availableColors"
                  mode="hexa"
                  hide-inputs
                  hide-canvas
                  hide-sliders
                  show-swatches
                  dot-size="2"
                  width="1200"
                  swatches-max-height="150"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  :disabled="disableMoveUpBtn(link)"
                  @click="moveUp(link)"
                  ><v-icon dense>mdi-arrow-up</v-icon></v-btn
                >
              </template>
              <span>Position nach oben bewegen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  :disabled="disableMoveDownBtn(link)"
                  @click="moveDown(link)"
                  ><v-icon dense>mdi-arrow-down</v-icon></v-btn
                >
              </template>
              <span>Position nach unten bewegen</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="deleteLink(link)">
              <v-icon left>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <v-col cols="12" align="center">
        <v-btn
          text
          :disabled="linksLocal.length > 4"
          color="success"
          class="ml-2"
          @click="addLink()"
        >
          <v-icon left>mdi-link-plus</v-icon>Link hinzufügen
        </v-btn>
      </v-col>
    </template>
  </div>
</template>

<script>
export default {
  name: "links-editor",
  props: {
    links: {},
  },
  model: {
    prop: "links",
    event: "linksChange",
  },
  data() {
    return {
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        link: (value) =>
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            value
          ) || "Ungültiger Link",
      },
    };
  },
  computed: {
    linksLocal: {
      get: function () {
        return this.links;
      },
      set: function (value) {
        this.$emit("linksChange", value);
      },
    },
  },
  methods: {
    openMdiIconLibrary() {
      var res = confirm(
        "Hinweis: Mit dem Öffnen der MDI Icon Library verlässt Du Mira One und wirst auf eine externe Internetseite weitergeleitet. Für die Inhalte können wir keine Haftung übernehmen.\n\nSuche nach einem passenden Icon und kopiere den Namen des Icons in das Eingabefeld."
      );
      if (res == true) {
        window.open("https://materialdesignicons.com/", "_blank");
      }
    },
    addLink() {
      if (this.linksLocal.length < 6) {
        this.linksLocal.push({
          title: "",
          url: "",
          color: "#F44336FF",
          icon: "link-variant",
        });
      } else {
        alert("Fehler: Es können nicht mehr als fünf Links hinterlegt werden.");
      }
    },
    deleteLink(link) {
      this.linksLocal.splice(this.linksLocal.indexOf(link), 1);
    },
    disableMoveUpBtn(item) {
      var index = this.linksLocal.indexOf(item);
      if (index === 0) {
        return true;
      } else {
        return false;
      }
    },
    disableMoveDownBtn(item) {
      var index = this.linksLocal.indexOf(item);
      var length = this.linksLocal.length;
      if (index === length - 1) {
        return true;
      } else if (index + 1 === length) {
        return true;
      } else {
        return false;
      }
    },
    moveUp(item) {
      const from = this.linksLocal.indexOf(item);
      const to = from - 1;
      this.moveElement(to, from);
    },
    moveDown(item) {
      const from = this.linksLocal.indexOf(item);
      const to = from + 1;
      this.moveElement(to, from);
    },
    moveElement(to, from) {
      this.linksLocal.splice(to, 0, this.linksLocal.splice(from, 1)[0]);
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
