<template>
  <div>
    <v-list-item
      v-for="(link, index) in items"
      :key="index"
      link
      dense
      :href="link.url"
      target="_blank"
    >
      <data-avatar
        :color="link.color"
        :icon="link.icon"
        class="mr-4"
        size="40"
      ></data-avatar>
      <v-list-item-content>
        <v-list-item-title class="subtitle-1 font-weight-medium mb-1">{{
          link.title
        }}</v-list-item-title>
        <v-list-item-subtitle>{{ getHostname(link.url) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn color="red darken-1" icon>
          <v-icon dense> mdi-open-in-new </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
export default {
  name: "link-list-items",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    DataAvatar,
  },
  methods: {
    getHostname(url) {
      return new URL(url).hostname;
    },
  },
};
</script>
