<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="subheading"
          >Bevorstehende Veranstaltungen</span
        ></v-card-title
      >
      <v-data-iterator
        :items="eventsMatched"
        hide-default-footer
        sort-by="duration.start.firebaseFormat"
        :sort-desc="false"
        no-data-text="Keine Veranstaltungen bevorstehend"
      >
        <template v-slot:default="props">
          <v-card flat>
            <v-list>
              <v-list-item
                v-for="event in props.items"
                :key="event.meta.id"
                link
                dense
                :to="{
                  name: 'scheduling-event-details-tab-general',
                  params: { itemId: event.meta.id },
                }"
              >
                <div
                  class="statusDisplay"
                  v-bind:style="createStatusStyle(event.userResponse.status.id)"
                ></div>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 font-weight-medium mb-1">
                    <v-icon
                      v-if="
                        $store.state.user.user.settings.accessability
                          .useColorblindMode
                      "
                      small
                      :color="statusColorAsHex(event.userResponse.status.id)"
                      class="mr-2"
                    >
                      {{ statusIcon(event.userResponse.status.id) }}
                    </v-icon>
                    <span>{{ event.title }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <p style="line-height: 1.6" class="mb-0">
                      <v-icon small left>mdi-calendar-badge</v-icon
                      >{{ event.type.title }}
                      <br />
                      <v-icon small left>mdi-calendar-clock</v-icon
                      >{{ event.duration.start.timestamp }} bis
                      {{
                        tryToDisplayOnlyEndTime(
                          event.duration.start.firebaseFormat,
                          event.duration.end.firebaseFormat,
                          event.duration.end.timestamp
                        )
                      }}<br />
                      <v-icon small left v-if="event.location.name"
                        >mdi-map-marker</v-icon
                      >{{ event.location.name }}<br />
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="red darken-1" icon>
                    <v-icon large> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                link
                dense
                style="height: 10px"
                :to="{
                  name: 'scheduling-events',
                }"
              >
                <v-list-item-content> </v-list-item-content>
                <v-list-item-action-text
                  >Weitere Veranstaltungen anzeigen</v-list-item-action-text
                >
                <v-list-item-action>
                  <v-btn color="red darken-1" icon>
                    <v-icon> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
        <template v-slot:no-data>
          <div
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <p class="subtitle-1">Keine Veranstaltungen vorhanden.</p>
          </div>
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
import { SCHEDULING } from "@/store/modules.js";
import { GET_EVENTS } from "@/store/action-types.js";
export default {
  name: "events-dashboard-card",
  props: {},
  components: {},
  computed: {
    events() {
      return this.$store.state.scheduling.events;
    },
    eventsStatus() {
      return this.$store.state.scheduling.eventsStatus;
    },
    eventsMatched() {
      // Add status to events
      const b = this.events.map((event) => {
        const u = this.eventsStatus.find(
          (u) => u.userResponse.eventId === event.meta.id
        );
        return {
          ...event,
          ...u,
        };
      });
      return b;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${SCHEDULING}${GET_EVENTS}`, {
        organizationId: this.$route.params.organizationId,
        withStatus: true,
        query: {
          onlyOwn: true,
          ownUserGroup: this.$store.state.organization.membership.group.id,
        },
        // withStatus: this.$store.getters["organization/checkPermissionByID"](
        //   `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`
        // )
        //   ? true
        //   : false,
        paginationLimit: 3,
      });
    },
    tryToDisplayOnlyEndTime(startTimestamp, endTimestamp, endTimestampString) {
      var startDate = startTimestamp.toDate();
      var endDate = endTimestamp.toDate();
      if (
        startDate.getDate() == endDate.getDate() &&
        startDate.getMonth() == endDate.getMonth() &&
        startDate.getFullYear() == endDate.getFullYear()
      ) {
        return endDate.toLocaleTimeString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return endTimestampString;
      }
    },
    statusIcon(item = "") {
      switch (item) {
        case "0":
          return "mdi-help";
        case "1":
          return "mdi-check";
        case "2":
          return "mdi-close";
        case "3":
          return "mdi-minus";
        case "4":
          return "mdi-airplane";
        case "":
          return "mdi-alert-outline";
        default:
          return "";
      }
    },
    statusColorAsHex(item = "") {
      switch (item) {
        case "0":
          return "#455a64";
        case "1":
          return "#4caf50";
        case "2":
          return "#ff5252";
        case "3":
          return "#fb8c00";
        case "4":
          return "#2196f3";
        case "":
          return "#455a64";
        default:
          return "";
      }
    },

    createStatusStyle(status = "") {
      const style = "background-color: " + this.statusColorAsHex(status);
      return style;
    },
  },
};
</script>

<style>
.statusDisplay {
  height: 65px;
  width: 8px;
  border-radius: 0px 40px 40px 0px;
  margin-left: -16px;
  margin-right: 10px;
}
</style>
