var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"subheading"},[_vm._v("Aktive Abfragen")])]),_c('v-data-iterator',{attrs:{"items":_vm.requestsMatched,"hide-default-footer":"","sort-by":"timeSlots.start.timestamp","sort-desc":false,"no-data-text":"Keine Abfragen bevorstehend"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-card',{attrs:{"flat":""}},[_c('v-list',[_vm._l((props.items),function(request){return _c('v-list-item',{key:request.meta.id,attrs:{"link":"","dense":"","to":{
                name: 'scheduling-requests-details-tab-general',
                params: { itemId: request.meta.id },
              }}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium mb-1"},[_c('span',[_vm._v(_vm._s(request.title)+" – "+_vm._s(request.type.title))])]),_c('v-list-item-subtitle',[(_vm.responseIsComplete(request))?_c('v-chip',{staticClass:"font-weight-medium green--text text--darken-4 my-1",attrs:{"x-small":"","color":"green lighten-4"}},[_vm._v("Beantwortet")]):(
                      request.timeSlots.data.length >
                      request.userResponse.status.length
                    )?_c('v-chip',{staticClass:"font-weight-medium orange--text text--darken-4 my-1",attrs:{"x-small":"","color":"orange lighten-4"}},[_vm._v("Rückmeldung unvollständig")]):_c('v-chip',{staticClass:"font-weight-medium red--text text--darken-4 my-1",attrs:{"x-small":"","color":"red lighten-4"}},[_vm._v("Unbeantwortet")]),_c('p',{staticClass:"mt-1 mb-0",staticStyle:{"line-height":"1.6"}},[_c('span',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-range")]),_vm._v(_vm._s(request.timeSlots.start.formattedTimestamp)+" - "+_vm._s(request.timeSlots.end.formattedTimestamp)+" ")],1),_c('br'),(request.location.name)?_c('span',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-map-marker")]),_vm._v(_vm._s(request.location.name)),_c('br')],1):_vm._e(),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-multiselect")]),_vm._v(_vm._s(request.timeSlots.data.length)+" Slots ")],1)],1)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red darken-1","icon":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)}),_c('v-divider'),_c('v-list-item',{staticStyle:{"height":"10px"},attrs:{"link":"","dense":"","to":{
                name: 'scheduling-requests',
              }}},[_c('v-list-item-content'),_c('v-list-item-action-text',[_vm._v("Weitere Abfragen anzeigen")]),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red darken-1","icon":""}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],2)],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticStyle:{"height":"200px","display":"flex","justify-content":"center","align-items":"center"}},[_c('p',{staticClass:"subtitle-1"},[_vm._v("Keine Abfragen vorhanden.")])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }