<template>
  <div>
    <v-card>
      <v-card-title class="subheading"
        >Links<v-spacer></v-spacer
        ><v-btn
          v-if="allowedToEditLinks"
          depressed
          @click="toggleEditLinksDialog()"
          ><v-icon left>mdi-pencil</v-icon>Bearbeiten</v-btn
        ></v-card-title
      >
      <v-data-iterator
        :items="links"
        hide-default-footer
        no-data-text="Keine Links angepinnt"
      >
        <template v-slot:default="props">
          <v-card flat>
            <v-list>
              <link-list-items :items="props.items"></link-list-items>
            </v-list>
          </v-card>
        </template>
        <template v-slot:no-data>
          <div
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <p class="subtitle-1">Keine Links angepinnt.</p>
          </div>
        </template>
      </v-data-iterator>
    </v-card>
    <custom-dialog
      v-if="helpers.linksEditor.dialogModel"
      @close="toggleEditLinksDialog()"
      title="Links bearbeiten"
      width="xl"
      :errorMessage="helpers.linksEditor.errorMessage"
      :btnAction="updateLinks"
      :btnDisabled="!helpers.linksEditor.formIsValid"
      :loading="helpers.linksEditor.loading"
      btnText="Speichern"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.linksEditor.formIsValid"
          ref="form"
          @submit.prevent="updateLinks()"
        >
          <links-editor v-model="links"></links-editor>
        </v-form>
      </template>
    </custom-dialog>
    <v-snackbar v-model="snackbar.model" timeout="5000"
      >{{ snackbar.text }}.</v-snackbar
    >
  </div>
</template>

<script>
import LinkListItems from "@/components/my-organization/dashboard/LinkListItems.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import LinksEditor from "@/components/my-organization/dashboard/LinksEditor.vue";
import { ADMINISTRATION_CONFIG_GENERAL_update } from "@/data/permission-types.js";
import { ORGANISATION } from "@/store/modules.js";
import { UPDATE_ORGANIZATION_LINKS } from "@/store/action-types.js";
export default {
  name: "links-dashboard-card",
  props: {},
  components: {
    LinkListItems,
    LinksEditor,
    CustomDialog,
  },
  data() {
    return {
      snackbar: {
        model: false,
        text: "",
      },
      helpers: {
        linksEditor: {
          formIsValid: false,
          dialogModel: false,
          loading: false,
          errorMessage: "",
        },
      },
    };
  },
  computed: {
    links() {
      return this.$store.state.organization.activeOrganization.links;
    },
    allowedToEditLinks() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${ADMINISTRATION_CONFIG_GENERAL_update}`
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    toggleEditLinksDialog() {
      this.helpers.linksEditor.dialogModel =
        !this.helpers.linksEditor.dialogModel;
    },
    updateLinks() {
      this.$refs.form.validate();
      if (this.helpers.linksEditor.formIsValid) {
        this.form3IsLoading = true;
        this.$store
          .dispatch(`${ORGANISATION}${UPDATE_ORGANIZATION_LINKS}`, {
            organizationId: this.$route.params.organizationId,
            links: this.links,
          })
          .then(() => {
            this.toggleEditLinksDialog();
            this.form3IsLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Links erfolgreich aktualisiert.";
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
