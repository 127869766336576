<template>
  <div>
    <v-card>
      <v-card-title class="subheading">Offene Umfragen</v-card-title>
      <v-data-iterator
        :items="surveys"
        hide-default-footer
        :sort-desc="false"
        no-data-text="Keine Umfragen vorhanden"
      >
        <template v-slot:default="props">
          <v-card flat>
            <v-list>
              <survey-list-items
                :items="props.items"
                dense
                showLinkButton
              ></survey-list-items>
            </v-list>
          </v-card>
        </template>
        <template v-slot:no-data>
          <div
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <p class="subtitle-1">Keine Umfragen vorhanden.</p>
          </div>
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
import SurveyListItems from "@/components/my-organization/surveys/SurveyListItems.vue";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_SURVEYS } from "@/store/action-types.js";
export default {
  name: "surveys-dashboard-card",
  props: {},
  components: {
    SurveyListItems,
  },
  computed: {
    surveys() {
      return this.$store.state.myOrganization.surveys;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_SURVEYS}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          onlyActive: true,
          onlyOwn: true,
          ownUserGroup: this.$store.state.organization.membership.group.id,
        },
        paginationLimit: 3,
      });
    },
  },
};
</script>
