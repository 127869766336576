<template>
  <div>
    <NavigationBar titel="Dashboard"></NavigationBar>
    <section>
      <template>
        <v-container justify="center" align="center">
          <v-row v-if="showActiveLeaveCard">
            <v-col cols="12" sm="12" md="12">
              <active-leave-card
                :end="
                  $store.state.organization.membership.availability.activeLeave
                    .end.timestamp
                "
                :id="
                  $store.state.organization.membership.availability.activeLeave
                    .id
                "
              ></active-leave-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" v-if="showEventsCard">
              <events-dashboard-card></events-dashboard-card>
            </v-col>
            <v-col cols="12" md="6" v-if="showAvailabilityMonitorCard">
              <availability-status-overview-card></availability-status-overview-card>
            </v-col>
            <!-- </v-row> -->
            <!-- <v-row> -->
            <v-col cols="12" sm="12" md="6" v-if="showNewsCard">
              <news-dashboard-card></news-dashboard-card>
            </v-col>
            <v-col v-if="showRequestsCard" cols="12" md="6">
              <requests-dashboard-card></requests-dashboard-card>
            </v-col>
            <v-col v-if="showSurveysCard" cols="12" md="6">
              <surveys-dashboard-card></surveys-dashboard-card>
            </v-col>
            <v-col cols="12" md="6">
              <links-dashboard-card></links-dashboard-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";
import ActiveLeaveCard from "@/components/availability/leaves/ActiveLeaveCard.vue";
import EventsDashboardCard from "@/components/my-organization/dashboard/EventsDashboardCard.vue";
import NewsDashboardCard from "@/components/my-organization/dashboard/NewsDashboardCard.vue";
import LinksDashboardCard from "@/components/my-organization/dashboard/LinksDashboardCard.vue";
import RequestsDashboardCard from "@/components/my-organization/dashboard/RequestsDashboardCard.vue";
import SurveysDashboardCard from "@/components/my-organization/dashboard/SurveysDashboardCard.vue";
import AvailabilityStatusOverviewCard from "@/components/availability/AvailabilityStatusOverviewCard.vue";
import {
  SCHEDULING_EVENTS_read,
  MY_ORGANIZATION_SURVEYS_read,
  AVAILABILITY_REQUESTS_read,
  MY_ORGANIZATION_NEWS_read,
} from "@/data/permission-types.js";
export default {
  name: "my-organization-dashboard",
  components: {
    NavigationBar,
    // Subheader,
    LinksDashboardCard,
    EventsDashboardCard,
    RequestsDashboardCard,
    NewsDashboardCard,
    SurveysDashboardCard,
    AvailabilityStatusOverviewCard,
    ActiveLeaveCard,
  },
  data() {
    return {};
  },
  computed: {
    organization() {
      return this.$store.state.organization.activeOrganization;
    },
    showActiveLeaveCard() {
      return (
        this.$store.state.organization.membership.availability.isOnLeave ||
        false
      );
    },
    showEventsCard() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "scheduling.events"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${SCHEDULING_EVENTS_read}`
        )
      );
    },
    showSurveysCard() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "myOrganization.surveys"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${MY_ORGANIZATION_SURVEYS_read}`
        )
      );
    },
    showNewsCard() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "myOrganization.news"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${MY_ORGANIZATION_NEWS_read}`
        )
      );
    },
    showRequestsCard() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "availability.requests"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${AVAILABILITY_REQUESTS_read}`
        )
      );
    },
    showAvailabilityMonitorCard() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "availability"
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
  },
};
</script>
