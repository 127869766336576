var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"subheading"},[_vm._v("Bevorstehende Veranstaltungen")])]),_c('v-data-iterator',{attrs:{"items":_vm.eventsMatched,"hide-default-footer":"","sort-by":"duration.start.firebaseFormat","sort-desc":false,"no-data-text":"Keine Veranstaltungen bevorstehend"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-card',{attrs:{"flat":""}},[_c('v-list',[_vm._l((props.items),function(event){return _c('v-list-item',{key:event.meta.id,attrs:{"link":"","dense":"","to":{
                name: 'scheduling-event-details-tab-general',
                params: { itemId: event.meta.id },
              }}},[_c('div',{staticClass:"statusDisplay",style:(_vm.createStatusStyle(event.userResponse.status.id))}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium mb-1"},[(
                      _vm.$store.state.user.user.settings.accessability
                        .useColorblindMode
                    )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":_vm.statusColorAsHex(event.userResponse.status.id)}},[_vm._v(" "+_vm._s(_vm.statusIcon(event.userResponse.status.id))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(event.title))])],1),_c('v-list-item-subtitle',[_c('p',{staticClass:"mb-0",staticStyle:{"line-height":"1.6"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-badge")]),_vm._v(_vm._s(event.type.title)+" "),_c('br'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-clock")]),_vm._v(_vm._s(event.duration.start.timestamp)+" bis "+_vm._s(_vm.tryToDisplayOnlyEndTime( event.duration.start.firebaseFormat, event.duration.end.firebaseFormat, event.duration.end.timestamp ))),_c('br'),(event.location.name)?_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-map-marker")]):_vm._e(),_vm._v(_vm._s(event.location.name)),_c('br')],1)])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red darken-1","icon":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)}),_c('v-divider'),_c('v-list-item',{staticStyle:{"height":"10px"},attrs:{"link":"","dense":"","to":{
                name: 'scheduling-events',
              }}},[_c('v-list-item-content'),_c('v-list-item-action-text',[_vm._v("Weitere Veranstaltungen anzeigen")]),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red darken-1","icon":""}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],2)],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticStyle:{"height":"200px","display":"flex","justify-content":"center","align-items":"center"}},[_c('p',{staticClass:"subtitle-1"},[_vm._v("Keine Veranstaltungen vorhanden.")])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }